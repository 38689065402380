import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getOrderDetails } from "../api/orders";
// import { getOrderDetails } from "../api/apiClient"; // Adjust the import path

// Async thunk to fetch order details

export const fetchOrderDetails = createAsyncThunk(
  "orderDetails/fetchOrderDetails",
  async ({ orderID }, { rejectWithValue }) => {
    try {
      console.log("orderID", orderID);
      const response = await getOrderDetails(orderID);
      console.log("response", response);
      return response.data; // Return the data from the API
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Slice
const orderDetailsSlice = createSlice({
  name: "orderDetails",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {}, // Add reducers here if needed
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.data = null;
        console.log("Fetching order details...");
      })
      .addCase(fetchOrderDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        console.log("Order details fetched successfully:", action.payload);
      })
      .addCase(fetchOrderDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        console.error("Failed to fetch order details:", action.payload);
      });
  },
});

// Export the async thunk and the reducer
// export default orderDetailsSlice.reducer;

export const orderDetailsReducer = orderDetailsSlice.reducer;
