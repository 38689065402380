import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchProducts } from "../api/products";

// Thunk for fetching product data
export const getProductsData = createAsyncThunk(
  "products/getProductsData",
  async (
    {
      currentPage,
      currentSlideID,
      keyWord,
      currentSlideTitle,
      currentSlideType,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await fetchProducts({
        currentPage,
        currentSlideID,
        keyWord,
        currentSlideTitle,
        currentSlideType,
      });

      // console.log("response", response);
      // Treat "not found" as a valid response
      if (
        response.status === 404 &&
        response.data.message === "Not Found Products Data"
      ) {
        console.warn("No products found for the given search criteria.");
        return { status: false, message: "Not Found Products Data", data: [] }; // Treat as valid
      }

      // Normalize null data
      if (response.data?.data === null) {
        return { ...response.data, data: [] };
      }
      // console.log("response.data.data", response.data.data);
      // console.log("response", response.data);
      return response.data; // Return valid data
    } catch (error) {
      // Handle only genuine errors (not 404 for "Not Found Products Data")
      // console.error("API Error:", error);

      if (error.response?.data) {
        return rejectWithValue({
          ...error.response.data,
          data: [], // Normalize error response
        });
      }

      throw error; // Rethrow unexpected errors
    }
  }
);

// Initial state for the slice
const initialValues = {
  products: [],
  token: null,
  isLoading: false,
  isError: null,
  meta: null,
};

// Slice for managing products
const productsSlice = createSlice({
  name: "products",
  initialState: initialValues,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProductsData.fulfilled, (state, action) => {
      const { data, ...rest } = action.payload.data;
      state.isLoading = false;
      state.products = data || {};
      state.meta = rest;
    });
    builder.addCase(getProductsData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProductsData.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = action.payload?.message || "An error occurred.";
      // console.error("Error in products data:", state.isError);

      // Handle normalized errors and ensure `productsData` is always an array

      state.products = [];
    });
  },
});

// Export the reducer
export const productsReducer = productsSlice.reducer;
