import React, { useContext } from "react";
import ProductsAddRemove from "./productAddRemove";
import ProductsAddRemoveService from "./productAddRemoveService";
import { EmployeesContext } from "../contexts/EmployeesContext";
import { ServiceSearchQueryContext } from "../contexts/ServiceSearchQueryContext";

export default function FullCart({
  cartItems,
  setCartItems,
  setCartItemData,
  // handleGetEmployees,

  setIsShow,
}) {
  const { handleGetEmployees } = useContext(EmployeesContext);
  const { setSearchQuery, setCurrentPage } = useContext(
    ServiceSearchQueryContext
  );
  // Update the quantity in the cart
  const handleIncreaseQty = (item) => {
    setCartItemData(item);
  };
  const handleDecreaseQty = (index) => {
    const updatedItems = { ...cartItems };
    // console.log(updatedItems);
    if (updatedItems.product_qty[index] > 0) {
      updatedItems.product_qty[index] -= 1;
    }
    if (updatedItems.product_qty[index] == 0) {
      handleRemove(index);
    }

    // Update session storage and state
    sessionStorage.setItem("cartItems", JSON.stringify(updatedItems));
    setCartItems(updatedItems);
  };

  const handleRemove = (index) => {
    const updatedItems = { ...cartItems };

    updatedItems.product.splice(index, 1);
    updatedItems.product_id.splice(index, 1);
    updatedItems.product_price.splice(index, 1);
    updatedItems.product_qty.splice(index, 1);
    updatedItems.professionals.splice(index, 1);
    updatedItems.professionals_id.splice(index, 1);
    updatedItems.is_service.splice(index, 1);

    // Update session storage and state
    sessionStorage.setItem("cartItems", JSON.stringify(updatedItems));
    setCartItems(updatedItems);
  };
  const filteredCartItems = {};
  const isService = cartItems.is_service;
  Object.keys(cartItems || {}).forEach((key) => {
    filteredCartItems[key] = cartItems[key]?.filter(
      (_, index) => isService[index] === true
    );
  });
  return (
    <>
      {cartItems?.product?.map((data, i) => {
        return (
          <div key={i} className="product-cart">
            <div className="p-3 rounded-xl">
              <div className="flex items-center gap-3">
                <div
                  className="w-[70px]  h-[130px]  sm:w-[90px] md:w-[110px] lg:w-[130px]  xl:w-[150px]  2xl:w-[180px]  3xl:w-[210px] 4xl:w-[230px] 5xl:w-[250px] 6xl:w-[270px] 7xl:w-[300px] 
                 sm:h-[150px] md:h-[160px] lg:h-[180px]  xl:h-[210px]  2xl:h-[240px]  3xl:h-[270px] 4xl:h-[300px] 5xl:h-[330px] 6xl:h-[360px] 7xl:h-[400px] "
                >
                  <img
                    // src={data?.image}
                    src={
                      data?.type === "service"
                        ? cartItems.professionals[i].profile_url
                        : data?.image
                    }
                    // alt={data?.name}
                    alt={
                      data?.type === "service"
                        ? cartItems.professionals[i].user_name
                        : data?.name
                    }
                    className="w-full h-full object-center object-cover"
                    loading="lazy"
                  />
                </div>
                <div className="w-full">
                  <div className="">
                    <h3 className="font-rosarivo text-[18px] sm:text-[24px] md:text-[30px] lg:text-[38px] xl:text-[45px]  2xl:text-[56px]  3xl:text-[66px] 4xl:text-[75px] 5xl:text-[80px] 6xl:text-[87px] 7xl:text-[100px] font-bold">
                      {data?.descriptions[0]?.name ||
                        data?.keyword ||
                        "No Name"}
                    </h3>
                    <p className="text-main text-[10px] sm:text-[14px] md:text-[18px] lg:text-[24px] xl:text-[28px]  2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px] font-bold">
                      {data?.type === "service" ? (
                        cartItems.professionals[i].user_name
                      ) : (
                        <span>
                          {data?.price} {data?.currancy || "SAR"}
                        </span>
                      )}

                      {/* {data?.price}
                      {data?.currancy || "SAR"} */}
                    </p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    {data?.type === "service" ? (
                      <ProductsAddRemoveService
                        item={data}
                        index={i}
                        handleIncreaseQty={handleIncreaseQty}
                        handleDecreaseQty={handleDecreaseQty}
                        cartItems={cartItems}
                        handleGetEmployees={handleGetEmployees}
                        filteredCartItems={filteredCartItems}
                        setIsShow={setIsShow}
                        setSearchQuery={setSearchQuery}
                        setCurrentPage={setCurrentPage}
                      />
                    ) : (
                      <ProductsAddRemove
                        item={data}
                        handleIncreaseQty={handleIncreaseQty}
                        handleDecreaseQty={handleDecreaseQty}
                        cartItems={cartItems}
                      />
                    )}
                    {/* <ProductsAddRemove
                      item={data}
                      handleIncreaseQty={handleIncreaseQty}
                      handleDecreaseQty={handleDecreaseQty}
                      cartItems={cartItems}
                    /> */}
                    {/* <ProductsAddRemoveService
                      item={data}
                      index={i}
                      handleIncreaseQty={handleIncreaseQty}
                      handleDecreaseQty={handleDecreaseQty}
                      cartItems={cartItems}
                      handleGetEmployees={handleGetEmployees}
                      filteredCartItems={filteredCartItems}
                    /> */}
                    <div
                      onClick={() => handleRemove(i)}
                      className="flex justify-center items-center my-auto  cursor-pointer rounded-[12px] h-[2dvh] w-[2dvh]  "
                    >
                      <img
                        src="./images/fi_trash.svg"
                        alt="Delete"
                        className="h-[8dvw] w-[8dvw] mx-auto object-contain"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
