import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchCategories,
  fetchStandardCategories,
  fetchStandardProducts,
} from "../api/categories";

const allCategories = {
  id: 97,
  parent: "",
  sort: 0,
  status: 1,
  descriptions: [{ title: "All" }],
  title: "All",
  top: 1,
};

// Thunk to fetch categories and standard categories based on standard products
export const fetchAllSlides = createAsyncThunk(
  "categories/fetchAllSlides",
  async (_, { rejectWithValue }) => {
    try {
      // Fetch standard products first
      const standardProductsResponse = await fetchStandardProducts();
      const standardProducts = standardProductsResponse.data?.data || [];
      // console.log(standardProducts, "standardProducts");
      if (!Array.isArray(standardProducts)) {
        throw new Error("Standard products response is not an array");
      }

      // Fetch categories and standard categories concurrently
      const [categoriesResponse, standardCategoriesResponse] =
        await Promise.all([
          fetchCategories(),
          fetchStandardCategories({
            standardProducts,
          }),
        ]);

      // Extract data from responses
      const categories = categoriesResponse.data.data?.data || [];
      const standardCategories = standardCategoriesResponse.data?.data || [];

      // Combine results
      return [...categories, ...standardCategories];
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch slides"
      );
    }
  }
);

// Slice definition
const categoriesSlice = createSlice({
  name: "categories",
  initialState: {
    slides: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle fetchAllSlides
      .addCase(fetchAllSlides.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllSlides.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Append allCategories at the end of the combined result
        state.slides = [...action.payload, allCategories];
      })
      .addCase(fetchAllSlides.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch slides";
      });
  },
});

export const categoriesReducer = categoriesSlice.reducer;
