import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServicesData } from "../../services/Services/servicesSlice";
import Loader from "../../components/loader";
import ServicesCard from "./sections/servicesCard";
import { useNavigate } from "react-router-dom";
import SelectedServices from "./sections/selectedServices";
import SearchButton from "../../components/SearchButton";
import { FaLongArrowAltRight } from "react-icons/fa";
import Cart from "../../components/Cart";
import BackButton from "../../components/BackButton";
import HandleEmptyCart from "../../components/HandleEmptyCart";
import EmployeesPop from "./sections/employeesPop";
import { EmployeesContext } from "../../contexts/EmployeesContext";
import { getEmployeesAndSpecialists } from "../../services/Employees/emploeesSlice";
import Pagination from "../../components/Pagination";
import { ServiceSearchQueryContext } from "../../contexts/ServiceSearchQueryContext";

export default function Services() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { searchQuery, setSearchQuery, currentPage, setCurrentPage } =
    useContext(ServiceSearchQueryContext);

  // const [currentPage, setCurrentPage] = useState(1);
  const {
    selectedEmployeeId,
    setSelectedEmployeeId,
    serviceSelectedId,
    setServiceSelectedId,
    proffessionalsModel,
    setProffessionalsModel,
    isShow,
    setIsShow,
    handleGetEmployees,
  } = useContext(EmployeesContext);

  const servicesData = useSelector((state) => state?.services);
  // console.log("servicesData", servicesData);
  const servicesIsLoading = servicesData?.isLoading;
  const services = servicesData?.services;
  const meta = servicesData?.meta;

  // console.log("services", services);
  // const isLoadingEmployee = useSelector((state) => state.getEmploees?.status);
  // console.log("Status:", isLoadingEmployee);
  // console.log("services", services);
  // const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  // const [serviceSelectedId, setServiceSelectedId] = useState(null);
  // const [proffessionalsModel, setProffessionalsModel] = useState(false);
  // const [isShow, setIsShow] = useState(false);

  // const handleGetEmployees = (serviceId) => {
  //   // const branchId = sessionStorage.getItem("brunch_id");
  //   setServiceSelectedId(serviceId);
  //   setProffessionalsModel(true);
  //   setSelectedEmployeeId(null);
  // };

  useEffect(() => {
    if (serviceSelectedId) {
      // Dispatch the action to get employees whenever `serviceSelectedId` changes
      const branchId = sessionStorage.getItem("brunch_id");
      dispatch(
        getEmployeesAndSpecialists({
          serviceId: serviceSelectedId,
          branchId,
        })
      );
    }
  }, [serviceSelectedId, currentPage]);

  useEffect(() => {
    dispatch(
      getServicesData({ keyWord: searchQuery, currentPage: currentPage })
    );
  }, [dispatch, searchQuery, currentPage]);

  const [cartItems, setCartItems] = useState({
    product: [],
    product_id: [],
    product_price: [],
    product_qty: [],
    professionals: [],
    professionals_id: [],
    is_service: [],
  });

  useEffect(() => {
    const storedItems = JSON.parse(sessionStorage.getItem("cartItems")) || {
      product: [],
      product_id: [],
      product_price: [],
      product_qty: [],
      professionals: [],
      professionals_id: [],
      is_service: [],
    };
    setCartItems(storedItems);
  }, []);
  const handleAddToCart = async (item) => {
    let updatedProducts = [...cartItems.product];
    let updatedProductsId = [...cartItems.product_id];
    let updatedProductsPrice = [...cartItems.product_price];
    let updatedQuantities = [...cartItems.product_qty];
    let updatedProfessionals = [...cartItems.professionals];
    let updatedProfessionalsId = [...cartItems.professionals_id];
    let updatedIsService = [...cartItems.is_service];

    const isService = item.type === "service";
    // Find index based on both product_id and professionals_id
    const existingProductIndex = updatedProducts.findIndex(
      (cartProduct, index) =>
        cartProduct.id === item.id &&
        (updatedProfessionalsId[index] === item.professionals_id || !isService)
    );

    if (existingProductIndex >= 0) {
      // If a matching product and professionals_id exists, increment quantity
      updatedQuantities[existingProductIndex] += 1;
    } else {
      // Otherwise, add it as a new item
      updatedProducts.push(item);
      updatedProductsId.push(item.id);
      updatedProductsPrice.push(item.customer_price);
      updatedQuantities.push(1);
      updatedProfessionals.push(item.professional || "no");
      updatedProfessionalsId.push(item.professionals_id || null);
      updatedIsService.push(isService); // Assuming this should always be true
    }

    const updatedCartItems = {
      product: updatedProducts,
      product_id: updatedProductsId,
      product_price: updatedProductsPrice,
      product_qty: updatedQuantities,
      professionals: updatedProfessionals,
      professionals_id: updatedProfessionalsId,
      is_service: updatedIsService,
    };

    // Update state and sessionStorage
    setCartItems(updatedCartItems);
    sessionStorage.setItem("cartItems", JSON.stringify(updatedCartItems));

    setIsShow(true);
    setTimeout(() => {
      setIsShow(false);
    }, 2000);
  };

  const totalPrice =
    Math.round(
      cartItems?.product?.reduce(
        (total, product, index) =>
          total + product.price * cartItems.product_qty[index],
        0
      ) * 10
    ) / 10;
  // console.log("cartItems Services", cartItems);
  const handleNextPage = () => {
    if (currentPage < meta?.last_page) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };
  console.log(cartItems);
  return (
    <section className="pt-8 h-full flex flex-col justify-between">
      <BackButton />
      <HandleEmptyCart cartItems={cartItems} setIsShow={setIsShow} />
      <div className="">
        <div
          className={`${
            isShow ? "" : "hidden"
          } fixed top-0 right-0 h-[100%] w-[100%] bg-white bg-opacity-50 z-[45]`}
        ></div>
        <div
          className={`${
            isShow ? "w-[56vw]  " : "w-[0vw]"
          } fixed right-0 top-0 h-[80vh] bg-white border pt-10 flex flex-col transition-all duration-500`}
          style={{ zIndex: 55 }}
        >
          <div className="flex justify-between items-center px-8">
            <h4 className="text-[1.7dvh] ">Order summary</h4>
            <h4
              onClick={() => {
                setIsShow(false);
              }}
              className="cursor-pointer flex items-center gap-3 text-[1.3dvh] "
            >
              <p className="leading-2">Hide</p>
              <FaLongArrowAltRight />
            </h4>
          </div>
          <div
            className="flex-grow px-8 overflow-y-auto"
            id="shop2-cart-container"
          >
            <Cart
              cartItems={cartItems}
              setCartItems={setCartItems}
              setCartItemData={handleAddToCart}
              handleGetEmployees={handleGetEmployees}
              setIsShow={setIsShow}
            />
          </div>
          <div className="bg-gray-50 border-t-2 border-gray-200 py-14 px-8 space-y-8">
            <div className="flex justify-between items-center text-[12px] sm:text-[16px] md:text-[20px] lg:text-[24px] xl:text-[28px]  2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px]">
              <h4 className="font-bold ">Total</h4>
              <h4 className="flex items-center gap-3">SAR {totalPrice}</h4>
            </div>
            <button
              onClick={() => navigate("/phoneVerification")}
              className="block w-full bg-[#e7ded5] text-[#3F1A01] border border-main py-3 font-bold rounded-[8px] md:rounded-[12px] lg:rounded-[16px] text-[16px] md:text-[20px] lg:text-[24px] xl:text-[28px]  2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px]"
            >
              Confirm
            </button>
          </div>
        </div>

        <div className="container-box flex justify-between items-center">
          <h3 className="!z-10  text-[2.2dvh] font-bold mt-[1dvh]">
            Select Services
          </h3>
          <div className="relative flex gap-[1dvh]">
            <SearchButton
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
            <button
              onClick={() => setIsShow(true)}
              disabled={cartItems.product.length === 0}
              className={`${cartItems.product.length === 0 && "opacity-40"}`}
            >
              <div className="flex items-center gap-3 py-[.5dvh] px-[1.1dvh]  border-black/40 border-[0.16dvh] rounded-[.8dvh] bg-[#e7ded5]">
                <p className="text-[1.3dvh] text-[#3F1A01]  font-bold">Cart </p>
                <img
                  src="./images/fi_shopping-cart.svg"
                  alt="ShoppingCartIcon"
                  className="w-[1.4dvh] h-[1.4dvh]  cursor-pointer"
                  loading="lazy"
                />
              </div>
            </button>
          </div>
        </div>
        {servicesIsLoading ? (
          <Loader />
        ) : services && services.length > 0 ? (
          <div className="container-box w-full grid gap-4 grid-cols-3  mt-[2dvh] py-[1dvh] mx-auto">
            {services?.map((item, i) => (
              <div className="" key={i}>
                <ServicesCard
                  key={i}
                  item={item}
                  handleAddToCart={handleAddToCart}
                  handleGetEmployees={handleGetEmployees}
                  serviceSelectedId={serviceSelectedId}
                />
              </div>
            ))}
            <EmployeesPop
              item={services?.find((x) => x.id === serviceSelectedId) ?? null}
              cartItems={cartItems}
              setCartItems={setCartItems}
              serviceSelectedId={serviceSelectedId}
              proffessionalsModel={proffessionalsModel}
              setProffessionalsModel={setProffessionalsModel}
              setIsShow={setIsShow}
              setServiceSelectedId={setServiceSelectedId}
              setSelectedEmployeeId={setSelectedEmployeeId}
              selectedEmployeeId={selectedEmployeeId}
              setSearchQuery={setSearchQuery}
            />
          </div>
        ) : (
          <p className=" text-center w-full text-[3dvh] text-main mt-[2dvh]">
            No Services found from your search!!
          </p>
        )}
      </div>
      <div className=" bottom-0 left-0 right-0 bg-white">
        {/* Pagination*/}
        <Pagination
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
          currentPage={currentPage}
          meta={meta}
        />
        <SelectedServices
          cartItems={cartItems}
          setCartItems={setCartItems}
          handleAddToCart={handleAddToCart}
          setIsShow={setIsShow}
          handleGetEmployees={handleGetEmployees}
          setSearchQuery={setSearchQuery}
          setCurrentPage={setCurrentPage}
          // handleDecreaseQty={handleDecreaseQty}
          // handleIncreaseQty={handleIncreaseQty}
          // setIsShow={setIsShow}
        />
      </div>
    </section>
  );
}
