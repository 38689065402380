import axiosClient from "./axiosClient";
import { apiEndpoints, secureBusinessKey } from "./apiEndpoints";

export const createOrder = (orderData) =>
  axiosClient.post(apiEndpoints.orderId.url, orderData, {
    headers: { "secure-business-key": secureBusinessKey },
  });

export const makeReservation = (reservationData) =>
  axiosClient.post(apiEndpoints.orderReservation.url, reservationData, {
    headers: { "secure-business-key": secureBusinessKey },
  });
export const getOrderDetails = (orderId) =>
  axiosClient.get(apiEndpoints.orderDetails.url(orderId), {
    headers: {
      apikey: apiEndpoints.orderDetails.apikey,
      "secure-business-key": secureBusinessKey,
      "Accept-Language": "en",
      apiconnection: "appmobile",

      // uuid: apiEndpoints.employees.uuid,
    },
  });
export const getAllReservationDetails = ({ userId, token }) =>
  axiosClient.get(apiEndpoints.reservationDetails.url(userId), {
    headers: {
      "secure-business-key": secureBusinessKey,
      "Accept-Language": "en",
      "user-token": token,
      lang: "en",
      // uuid: apiEndpoints.employees.uuid,
    },
  });
