import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import Loader from "../../../components/loader";

function EmployeesPop({
  cartItems,
  setCartItems,
  proffessionalsModel,
  setProffessionalsModel,
  item,
  setIsShow,
  setServiceSelectedId,
  selectedEmployeeId,
  setSelectedEmployeeId,
  setSearchQuery,
}) {
  const [tooltipPosition, setTooltipPosition] = useState({
    left: "100vw",
  });
  const confirmButtonRef = useRef(null);

  // const [showArrow, setShowArrow] = useState(true); // State to control arrow visibility
  const isLoadingEmployee = useSelector((state) => state.getEmploees?.status);
  // console.log(isLoadingEmployee, "isLoadingEmployee");

  const employees = useSelector(
    (state) => state?.getEmploees?.availableEmployees
  );

  // console.log(employees, "emps");
  const positionElement = (item) => {
    const element = document.getElementById(`service-${item.id}`);
    if (element) {
      const rect = element.getBoundingClientRect();
      // console.log("Element Position:", rect); // Debugging
      const toDvh = (dvhValue) => (window.innerHeight * dvhValue) / 100;
      const offset = toDvh(1.5);
      return {
        top: `${rect.bottom + window.scrollY + offset}px`, // Position it right below the service card
        left: `${rect.left + window.scrollX}px`, // Align left with the service card
        right: `${rect.right + window.scrollX}px`, // Align left with the service card
        bottom: `${rect.left + window.scrollX}px`, // Align left with the service card
      };
    }
    return { top: "0", left: "0", bottom: "0", right: "0" };
  };
  const scrollToConfirmButton = () => {
    if (confirmButtonRef.current) {
      confirmButtonRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };
  useEffect(() => {
    if (item) {
      const position = positionElement(item);
      setTooltipPosition(position); // Update the tooltip position after item is set
      // setShowArrow(true); // Ensure the arrow is visible when item changes

      // Prevent horizontal scroll by adding overflow-x: hidden to body
      document.body.style.overflowX = "hidden";
    }

    return () => {
      document.body.style.overflowX = "auto"; // Reset overflow-x when the component unmounts
    };
  }, [item]); // Run only when `item` changes

  const handleAddToCart = async (item, selectedEmployeeId) => {
    let updatedProducts = [...cartItems.product];
    let updatedProductsId = [...cartItems.product_id];
    let updatedProductsPrice = [...cartItems.product_price];
    let updatedQuantities = [...cartItems.product_qty];
    let updatedProfessionals = [...cartItems.professionals];
    let updatedProfessionalsId = [...cartItems.professionals_id];
    let updatedIsService = [...cartItems.is_service];

    const selectedProfessional = employees.find(
      (emp) => emp.user_id === selectedEmployeeId
    );

    // Find index based on both product_id and professionals_id
    const existingProductIndex = updatedProducts.findIndex(
      (cartProduct, index) =>
        cartProduct.id === item.id &&
        updatedProfessionalsId[index] === selectedEmployeeId
    );

    if (existingProductIndex >= 0) {
      // Increment quantity for the specific product-professional pair
      updatedQuantities[existingProductIndex] += 1;
    } else {
      // Add a new entry for this product-professional pair
      updatedProducts.push(item);
      updatedProductsId.push(item.id);
      updatedProductsPrice.push(item.customer_price);
      updatedQuantities.push(1); // Start with quantity 1 for new entry
      updatedProfessionals.push(selectedProfessional || null);
      updatedProfessionalsId.push(selectedEmployeeId || null);
      updatedIsService.push(true); // Assuming always true for new items
    }

    const updatedCartItems = {
      product: updatedProducts,
      product_id: updatedProductsId,
      product_price: updatedProductsPrice,
      product_qty: updatedQuantities,
      professionals: updatedProfessionals,
      professionals_id: updatedProfessionalsId,
      is_service: updatedIsService,
    };

    // Update state and persist to session storage
    setCartItems(updatedCartItems);
    sessionStorage.setItem("cartItems", JSON.stringify(updatedCartItems));

    // UI updates
    setProffessionalsModel(false);
    setServiceSelectedId(null); // Hide the arrow after adding to cart
    // setProffessionalsModel(false);
    setSelectedEmployeeId(null);
    setIsShow(true);
    setTimeout(() => {
      setIsShow(false);
    }, 1000);
    setSearchQuery("");
  };

  const handleCancel = () => {
    setProffessionalsModel(false);
    setSelectedEmployeeId(null);
    // setShowArrow(false); // Hide the arrow on cancel
    setServiceSelectedId(null);
    setSearchQuery("");
  };

  if (!item) return null; // Ensure the component doesn't render if no item is selected

  return (
    <>
      {/* Arrow - Conditional Rendering */}
      {/* {showArrow && ( */}
      <div
        className={`absolute  ${proffessionalsModel ? "block" : "hidden"}`}
        style={{
          top: tooltipPosition.top, // Adjust to place the arrow above the tooltip, outside
          left: `calc(${tooltipPosition.left} + (${tooltipPosition.right} - ${tooltipPosition.left}) / 2 - 1.5dvh)`, // Center horizontally
          // translate: "5.6dvh", // Center the arrow horizontally

          // Center the arrow horizontally
          width: "3dvh", // Size of the arrow
          height: "3dvh",
          backgroundColor: "white",
          // border: "0.2dvh solid gray",
          transform: "translateY(-1.2dvh) rotate(45deg)", // Rotate to make the arrow shape
          zIndex: 51, // Ensure it's above other content
          boxShadow: "rgba(0, 0, 0, 0.2) 0.3dvh 0.3dvh 0.4dvh 0dvh inset",
          borderRadius: "0.4dvh",
          transition: "all 0.7s ease",
        }}
      ></div>
      {/* )} */}

      <div
        className={`${
          proffessionalsModel ? "block" : "hidden"
        } absolute  bg-white shadow-[inset_0_2dvh_1dvh_-2dvh_rgba(0,0,0,0.5)] px-[0.5dvh] pt-[0.5dvh] z-10 w-[calc(100dvw-20px)] max-h-[36dvh] overflow-y-auto  overflow-auto`}
        style={{
          position: "absolute", // Make sure the position is absolute
          top: tooltipPosition.top, // Apply top position from positionElement
          left: 0, // Apply left position from positionElement
          right: 0, // Apply left position from positionElement
          width: "100vw", // Full screen width
          transition: "all 0.7s ease", // Smooth transition
        }}
      >
        {" "}
        {isLoadingEmployee === "loading" ? (
          <div className="h-[25.5dvh]">
            <Loader />
          </div>
        ) : (
          <>
            <p
              onClick={handleCancel}
              className="cursor-pointer  p-[1dvh] text-[1.2dvh] underline  text-black font-medium flex items-center gap-[0.2dvh]"
            >
              <img
                src="./images/fi_x-square.svg"
                alt="check mark"
                className="w-[1.4dvh] h-[1.6dvh]  object-cover"
                loading="lazy"
              />{" "}
              <span className="mb-auto">close</span>
            </p>
            {/* Employees List */}
            <div className="grid grid-cols-4 gap-[0.5dvh]">
              {employees.length > 0 ? (
                employees.map((employee, i) => (
                  <div
                    onClick={() => {
                      setSelectedEmployeeId(employee.user_id);
                      setTimeout(scrollToConfirmButton, 100);
                    }}
                    key={i}
                    className="cursor-pointer p-2 rounded-md transition-transform duration-200"
                    style={{ zIndex: 53 }}
                  >
                    <div className="w-[10dvh] h-[10dvh] mx-auto relative">
                      {selectedEmployeeId === employee.user_id && (
                        <div className="absolute inset-0 bg-white bg-opacity-30 rounded-full flex items-center justify-center">
                          <img
                            src="./images/fi_check.svg"
                            alt="check mark"
                            className="w-[4dvh] h-[4dvh]"
                            loading="lazy"
                          />
                        </div>
                      )}
                      <img
                        src={employee.profile_url}
                        alt={employee.user_name}
                        className={`w-full h-full object-cover rounded-full ${
                          selectedEmployeeId === employee.user_id
                            ? "border-[0.7dvh] border-orange-300"
                            : "border-none"
                        }`}
                        loading="lazy"
                      />
                    </div>
                    <p className="text-center text-[1dvh] mt-[0.2dvh] font-bold">
                      {employee.user_name}
                    </p>
                  </div>
                ))
              ) : (
                <p className="text-center text-main font-bold py-[2dvh] text-[2.5dvh] col-span-4">
                  There is no available specialists for this service right now
                </p>
              )}
            </div>
            {/* Confirm and Cancel */}
            <div className="flex justify-center gap-[10dvh] py-[2dvh] ">
              {/* <p
            onClick={handleCancel}
            className="cursor-pointer text-[2dvh] text-red-500 font-medium flex items-center gap-2"
          >
            <FaRegTrashAlt /> Cancel
          </p> */}
              {selectedEmployeeId && (
                <button
                  onClick={async () => {
                    await handleAddToCart(item, selectedEmployeeId);
                  }}
                  className="cursor-pointer text-[1.3dvh]  ease-in-out  text-white bg-green-600 hover:bg-green-700 font-medium flex items-center gap-2 px-[2dvh] py-[1dvh] rounded-[1dvh] shadow-custom border-2 border-green-400 hover:border-green-600 transition-all duration-300"
                  ref={confirmButtonRef}
                >
                  <IoMdCheckmarkCircleOutline /> Confirm
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default EmployeesPop;
