import React from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
// import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export default function ProductsAddRemoveService({
  cartItems,
  handleDecreaseQty,
  item,
  handleGetEmployees,
  index,
  // filteredCartItems,
  setIsShow,
  setSearchQuery,
  setCurrentPage,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  // const employees = useSelector(
  //   (state) => state?.getEmploees?.getEmploees?.response
  // );
  // const epmArr = sessionStorage.getItem("employee_user_ids");
  // console.log("item", item.descriptions[0]?.name);
  // console.log("employeessds", employees);
  // console.log("epmArr", epmArr);

  const handleOnClick = () => {
    if (location.pathname === "/services") {
      handleGetEmployees(item?.id);
      setIsShow(false);
      setCurrentPage(1);
      setSearchQuery(item.descriptions[0]?.name);
    } else {
      navigate("/services");
      setSearchQuery(item.descriptions[0]?.name);
      setTimeout(() => {
        // Adding a timeout to allow the page to load.

        handleGetEmployees(item?.id);
        setIsShow(false);
      }, 1000); // Adjust delay as necessary for your application needs.
    }
  };

  return (
    <>
      <div className="flex justify-center items-center gap-[1.6dvh]">
        <div
          onClick={() => handleDecreaseQty(index)}
          className={` ${
            cartItems?.product_qty[index] === 0 ||
            (index === -1 &&
              "cursor-not-allowed opacity-35 bg-white pointer-events-none")
          } flex justify-center items-center bg-secondary cursor-pointer border
                       border-main rounded-full h-[2dvh] w-[2dvh]`}
        >
          <FaMinus
            className={`w-[1.2dvh] h-[1.2dvh] ${
              cartItems?.product_qty[index] === 0 ||
              (index === -1 ? "text-red-700" : "text-[#3F1A01]")
            } `}
          />
        </div>
        <p className="text-[1.4dvh] font-semibold">
          {cartItems?.product_qty[index] ?? 0}
        </p>
        <div
          onClick={handleOnClick}
          className="flex justify-center items-center bg-secondary cursor-pointer border border-main rounded-full h-[2dvh] w-[2dvh]"
        >
          <FaPlus className="w-[1.2dvh] h-[1.2dvh] text-[#3F1A01]" />
        </div>
      </div>
    </>
  );
}
