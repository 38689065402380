import { useNavigate } from "react-router-dom";
import ProductsAddRemoveService from "../../../components/productAddRemoveService";

function SelectedServices({
  cartItems,
  //   item,
  setCartItems,
  //   handleDecreaseQty,
  //   handleIncreaseQty,
  setSearchQuery,
  handleAddToCart,
  setIsShow,
  handleGetEmployees,
  setCurrentPage,
}) {
  const navigate = useNavigate();
  //   console.log("cartIn cart", cartItems);
  const handleIncreaseQty = (item) => {
    handleAddToCart(item);
  };

  const handleDecreaseQty = (index) => {
    const updatedItems = { ...cartItems };

    if (updatedItems.product_qty[index] > 0) {
      updatedItems.product_qty[index] -= 1;
    }

    if (updatedItems.product_qty[index] == 0) {
      updatedItems.product.splice(index, 1);
      updatedItems.product_id.splice(index, 1);
      updatedItems.product_price.splice(index, 1);
      updatedItems.product_qty.splice(index, 1);
      updatedItems.professionals.splice(index, 1);
      updatedItems.professionals_id.splice(index, 1);
      updatedItems.is_service.splice(index, 1);
    }

    // Update session storage and state
    sessionStorage.setItem("cartItems", JSON.stringify(updatedItems));
    setCartItems(updatedItems);
  };

  const filteredCartItems = {};

  const isService = cartItems.is_service;

  const handleRemove = (index) => {
    const updatedItems = { ...cartItems };

    updatedItems.product.splice(index, 1);
    updatedItems.product_id.splice(index, 1);
    updatedItems.product_price.splice(index, 1);
    updatedItems.product_qty.splice(index, 1);
    updatedItems.professionals.splice(index, 1);
    updatedItems.professionals_id.splice(index, 1);
    updatedItems.is_service.splice(index, 1);

    // Update session storage and state
    sessionStorage.setItem("cartItems", JSON.stringify(updatedItems));
    setCartItems(updatedItems);
  };

  // Iterate over the keys of the original object
  Object.keys(cartItems || {}).forEach((key) => {
    filteredCartItems[key] = cartItems[key]?.filter(
      (_, index) => isService[index] === true
    );
  });

  // console.log("filteredCartItems", filteredCartItems);
  return (
    <div>
      <div className=" flex flex-col justify-normal">
        <p className="text-[1.2dvh]  !py-[1dvh] w-full  text-center font-bold bg-main text-white">
          Selected Services
        </p>
        <div className="grid gap-[0.5dvh] px-[3dvh] !overflow-auto !max-h-[10dvh] py-[1dvh]">
          {filteredCartItems?.product?.length > 0 ? (
            cartItems.product.map(
              (data, i) =>
                data.type === "service" && (
                  <div
                    className="grid grid-cols-[56%_44%] gap-[1dvh] items-center"
                    key={i}
                  >
                    {/* Image and Description (50%) */}
                    <div className="flex gap-[1dvh]">
                      {/* Image */}
                      <div className="w-[4dvh] h-[4dvh] flex-shrink-0">
                        <img
                          src={cartItems.professionals[i].profile_url}
                          alt={
                            cartItems.professionals[i].user_name || "No Image"
                          }
                          className="w-full h-full object-center object-cover rounded-[1dvh]"
                          loading="lazy"
                        />
                      </div>
                      {/* Description */}
                      <div className="flex flex-col justify-center gap-[0.1dvh]">
                        <p className="text-black text-[1.6dvh] font-bold">
                          {data?.descriptions[0]?.name?.length > 23
                            ? `${data.descriptions[0].name.substring(0, 23)}...`
                            : data?.descriptions[0]?.name ||
                              (data?.keyword?.length > 23
                                ? `${data.keyword.substring(0, 23)}...`
                                : data?.keyword) ||
                              "No Name"}
                        </p>
                        <p className="text-main text-[1dvh] font-bold">
                          {cartItems.professionals[i].user_name}
                        </p>
                      </div>
                    </div>

                    {/* Add/Remove Service, Price, and Delete Button (50%) */}
                    <div className="grid grid-cols-[1fr_1fr_auto] items-center gap-[1dvh]">
                      {/* Add/Remove Service */}
                      <div className="flex justify-center">
                        <ProductsAddRemoveService
                          item={data}
                          index={i}
                          handleIncreaseQty={handleIncreaseQty}
                          handleDecreaseQty={handleDecreaseQty}
                          cartItems={cartItems}
                          handleGetEmployees={handleGetEmployees}
                          filteredCartItems={filteredCartItems}
                          setIsShow={setIsShow}
                          setSearchQuery={setSearchQuery}
                          setCurrentPage={setCurrentPage}
                        />
                      </div>

                      {/* Price */}
                      <div className="flex justify-center">
                        <p className="text-[1.4dvh] font-bold text-main">
                          {cartItems.product_price[i] *
                            cartItems.product_qty[i]}
                          SAR
                        </p>
                      </div>

                      {/* Delete Button */}
                      <div
                        onClick={() => handleRemove(i)}
                        className="flex justify-center items-center cursor-pointer rounded-[12px] h-[2dvh] w-[2dvh]"
                      >
                        <img
                          src="./images/fi_trash.svg"
                          alt="Delete"
                          className="h-full w-full object-contain"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                )
            )
          ) : (
            <div className="flex flex-col justify-between max-h-[11dvh]">
              <img
                src="./images/empty-cart.svg"
                alt="empty cart"
                className="w-[6dvh] h-[6dvh] m-auto"
                loading="lazy"
              />
              <p className="text-center text-[1.2dvh]">Your order is empty</p>
            </div>
          )}
        </div>

        <div className="w-full h-[8dvh] my-auto flex flex-col justify-center bg-[#FAF9F5]">
          <div className="flex justify-center gap-[.8dvh] px-[2dvh] ">
            <button
              onClick={() => {
                //   sessionStorage.removeItem("cartItems");
                navigate("/");
                sessionStorage.removeItem("cartItems");
              }}
              className="w-1/2 text-center text-[#A91115] font-bold bg-[#EFE8E2] 
                 rounded-[.6dvh] !py-[1dvh]  text-[1.3dvh] "
            >
              Cancel
            </button>
            {cartItems?.product?.length > 0 && (
              <button
                onClick={() => {
                  setIsShow(true);
                }}
                disabled={cartItems.product.length === 0}
                className="w-1/2 text-center text-[#FFFFFF] font-bold bg-[#D6C4B0] 
                 rounded-[.6dvh] !py-[1dvh]  text-[1.3dvh] "
              >
                Confirm
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectedServices;
