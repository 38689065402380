import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { secureBusinessKey, apiEndpoints } from "../api/apiEndpoints";

export const addToCart = createAsyncThunk("addToCart/addToCart", async () => {
  const cartItems = sessionStorage.getItem("cartItems");
  const valuse = cartItems
    ? JSON.parse(cartItems)
    : { product_id: [], item_qty: [] };
  // console.log(valuse);

  const options = {
    url: apiEndpoints.addToCart.url,
    method: "POST",
    headers: {
      lang: "en",
      "secure-business-key": secureBusinessKey,
    },
    data: valuse,
  };

  try {
    const { data } = await axios.request(options);
    // console.log(data);
    return data;
  } catch (error) {
    console.error(error);
    return error;
  }
});

export const changeQty = createAsyncThunk(
  "addToCart/changeQty",
  async (props) => {
    const options = {
      url: apiEndpoints.changeQty.url(props.id),
      method: "PUT",
      headers: {
        "secure-business-key": secureBusinessKey,
        status: props.state,
      },
    };

    try {
      const { data } = await axios.request(options);
      // console.log(data);
      return data;
    } catch (error) {
      // console.log(error);
      return error;
    }
  }
);

const initialValues = {
  addItems: null,
  isLoading: false,
  isError: null,
};

const addToCartSlice = createSlice({
  name: "addToCart",
  initialState: initialValues,
  extraReducers: (builder) => {
    builder
      .addCase(addToCart.fulfilled, (state, action) => {
        state.isLoading = false;
        state.addItems = action.payload;
      })
      .addCase(addToCart.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addToCart.rejected, (state, action) => {
        state.isError = action.error;
        state.isLoading = false;
      });
  },
});

export const addToCartReducer = addToCartSlice.reducer;
