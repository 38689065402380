import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllReservationDetails } from "../api/orders"; // Import your API function

// Async thunk for fetching reservation details
export const fetchReservationDetails = createAsyncThunk(
  "reservations/fetchReservationDetails",
  async ({ userId, token }, { rejectWithValue }) => {
    try {
      console.log("wh", userId);
      const response = await getAllReservationDetails({ userId, token });
      console.log("reservation____response.data", response.data);
      return response.data; // Assuming the API response has data in a .data field
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Slice for reservations
const reservationSlice = createSlice({
  name: "reservations",
  initialState: {
    details: {},
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReservationDetails.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchReservationDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.details = action.payload;
      })
      .addCase(fetchReservationDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

// Export the async thunk and the reducer

export const reservationDetailsSlice = reservationSlice.reducer;
