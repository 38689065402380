//
//
//
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductsData } from "../../services/Products/productsSlice";

import ProductsCard from "./sections/productsCard";
import { useNavigate } from "react-router-dom";
import Cart from "../../components/Cart";
import { FaLongArrowAltRight } from "react-icons/fa";
import Slider from "./sections/Slider";
import BackButton from "../../components/BackButton";
import SearchButton from "../../components/SearchButton";
import HandleEmptyCart from "../../components/HandleEmptyCart";
import SelectedProducts from "./sections/selectedProducts";
import ProductsLoader from "./sections/productsLoader";
import { EmployeesContext } from "../../contexts/EmployeesContext";
import Pagination from "../../components/Pagination";
export default function Shop() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const { setIsShow: setIsShowEmployees, handleGetEmployees } =
    useContext(EmployeesContext);

  const { status } = useSelector((state) => state?.getCategories);

  const ProductsData = useSelector((state) => state?.products);

  // console.log("ProductsData", ProductsData?.products[0]?.parent_type);
  const meta = ProductsData?.meta;
  // console.log("meta", meta);
  // useEffect(() => {
  //   setCurrentPage(currentPage);
  // }, [currentPage, setCurrentPage]);

  const productsIsLoading = ProductsData?.isLoading;

  const products = ProductsData?.products;
  // console.log("products", products);
  const [currentSlide, setCurrentSlide] = useState(0);
  // console.log(currentSlide);
  const [searchQuery, setSearchQuery] = useState("");
  const { slides = [] } = useSelector((state) => state?.getCategories);
  const currentSlideId = slides[currentSlide]?.id;
  const currentSlideTitle = slides[currentSlide]?.descriptions?.[0]?.title;
  const currentSlideType = slides[currentSlide]?.type || "standard";
  // console.log(currentSlideType);
  // const pageNum = 1;
  // if (currentSlideId) {
  //   dispatch(
  //     getProductsData({
  //       currentPage: currentPage,
  //       currentSlideID: currentSlideId,
  //       keyWord: searchQuery,
  //       currentSlideTitle: currentSlideTitle,
  //       currentSlideType: currentSlideType,
  //     })
  //   );
  // }
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  useEffect(() => {
    if (currentSlideId) {
      dispatch(
        getProductsData({
          currentPage: currentPage,
          currentSlideID: currentSlideId,
          keyWord: searchQuery,
          currentSlideTitle: currentSlideTitle,
          currentSlideType: currentSlideType,
        })
      );
    }
  }, [dispatch, currentSlideId, searchQuery, currentPage]);

  const [cartItems, setCartItems] = useState({
    product: [],
    product_id: [],
    product_price: [],
    product_qty: [],
    professionals: [],
    professionals_id: [],
    is_service: [],
  });
  //
  useEffect(() => {
    const storedItems = JSON.parse(sessionStorage.getItem("cartItems")) || {
      product: [],
      product_id: [],
      product_price: [],
      product_qty: [],
      professionals: [],
      professionals_id: [],
      is_service: [],
    };
    if (storedItems) {
      setCartItems(storedItems);
    }
  }, []);
  const setCartItemData = (item) => {
    // Make a copy of the current products in the cart
    let updatedProducts = [...cartItems.product];
    //
    let updatedProductsId = [...cartItems.product_id];
    let updatedProductsPrice = [...cartItems.product_price];
    // Make a copy of the current quantities in the cart
    let updatedQuantities = [...cartItems.product_qty];
    // Make a copy of the current professionals in the cart
    let updatedProfessionals = [...cartItems.professionals];
    let updatedProfessionalsId = [...cartItems.professionals_id];
    let updatedIsService = [...cartItems.is_service];
    // console.log("item", item);
    //
    // Check if the item is already in the cart
    const existingProductIndex = updatedProducts.findIndex(
      (cartProduct) => cartProduct.id === item.id
    );

    //
    // If the item is already in the cart, increment its quantity by 1
    if (existingProductIndex >= 0) {
      updatedQuantities[existingProductIndex] += 1;
    }
    // If the item is not in the cart, add it to the cart with a quantity of 1
    else {
      updatedProducts.push(item);
      updatedProductsId.push(item.id);
      updatedProductsPrice.push(item.customer_price);
      updatedQuantities.push(1);
      updatedProfessionals.push("no");
      updatedProfessionalsId.push(null);
      updatedIsService.push(false);
    }
    //
    // Create a new object with the updated products and quantities
    const updatedCartItems = {
      product: updatedProducts,
      product_id: updatedProductsId,
      product_price: updatedProductsPrice,
      product_qty: updatedQuantities,
      professionals: updatedProfessionals,
      professionals_id: updatedProfessionalsId,
      is_service: updatedIsService,
    };

    setCartItems(updatedCartItems);
    sessionStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    setIsShow(true);
    setTimeout(() => {
      setIsShow(false);
    }, 2000);
  };
  const handleAddToCart = (item) => {
    setCartItemData(item);
  };

  const totalPrice =
    Math.round(
      cartItems?.product?.reduce(
        (total, product, index) =>
          total + product.price * cartItems.product_qty[index],
        0
      ) * 10
    ) / 10;

  const handleIncreaseQty = (item) => {
    setCartItemData(item);
  };

  const handleDecreaseQty = (index) => {
    const updatedItems = { ...cartItems };

    if (updatedItems.product_qty[index] > 0) {
      updatedItems.product_qty[index] -= 1;
    }

    if (updatedItems.product_qty[index] == 0) {
      updatedItems.product.splice(index, 1);
      updatedItems.product_id.splice(index, 1);
      updatedItems.product_price.splice(index, 1);
      updatedItems.product_qty.splice(index, 1);
      updatedItems.professionals.splice(index, 1);
      updatedItems.professionals_id.splice(index, 1);
      updatedItems.is_service.splice(index, 1);
    }

    // Update session storage and state
    sessionStorage.setItem("cartItems", JSON.stringify(updatedItems));
    setCartItems(updatedItems);
  };

  // useEffect(() => {
  //   setCurrentPage(ProductsData?.meta?.current_page);
  // }, [currentPage, setCurrentPage]);
  const handleNextPage = () => {
    if (currentPage < meta?.last_page) {
      setCurrentPage((prev) => prev + 1);
      console.log("nextPage");
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <>
      <section className="pt-8 h-full overflow-hidden flex flex-col justify-between">
        <BackButton />
        <HandleEmptyCart cartItems={cartItems} setIsShow={setIsShow} />

        <div className="">
          <div
            className={`${
              isShow ? "" : "hidden"
            } fixed top-0 right-0 h-[100%] w-[100%] bg-white bg-opacity-50 z-[45]`}
          ></div>
          <div
            className={`${
              isShow ? "w-[56vw]  " : "w-[0vw]"
            } fixed right-0 top-0 h-[80vh] bg-white border  pt-10 flex flex-col transition-all duration-500`}
            style={{ zIndex: 55 }}
          >
            <div className="flex justify-between items-center px-8">
              <h4 className="text-[1.7dvh] ">Order summary</h4>
              <h4
                onClick={() => {
                  setIsShow(false);
                }}
                className="cursor-pointer flex items-center gap-3 text-[1.3dvh] "
              >
                <p className="leading-2">Hide</p>
                <FaLongArrowAltRight />
              </h4>
            </div>
            <div
              className="flex-grow px-8 overflow-y-auto"
              id="shop2-cart-container"
            >
              <Cart
                cartItems={cartItems}
                setCartItems={setCartItems}
                setCartItemData={setCartItemData}
                handleGetEmployees={handleGetEmployees}
                setIsShow={setIsShowEmployees}
              />
            </div>
            <div className="bg-gray-50 border-t-2 border-gray-200 py-14 px-8 space-y-8">
              <div className="flex justify-between items-center text-[12px] sm:text-[16px] md:text-[20px] lg:text-[24px] xl:text-[28px]  2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px]">
                <h4 className="font-bold ">Total</h4>
                <h4 className="flex items-center gap-3">SAR {totalPrice}</h4>
              </div>
              <button
                onClick={() => navigate("/phoneVerification")}
                className="block w-full bg-[#e7ded5] text-[#3F1A01] border border-main py-3 font-bold rounded-[8px] md:rounded-[12px] lg:rounded-[16px] text-[16px] md:text-[20px] lg:text-[24px] xl:text-[28px]  2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px]"
              >
                Confirm
              </button>
            </div>
          </div>
          <div className="container-box flex justify-between items-center">
            <h3 className="!z-10  text-[2.2dvh] font-bold mt-[1dvh]">
              Select Products
            </h3>
            <div className="relative flex gap-[1dvh]">
              <SearchButton
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
              <button
                onClick={() => setIsShow(true)}
                disabled={cartItems.product.length === 0}
                className={`${cartItems.product.length === 0 && "opacity-40"}`}
              >
                <div className="flex items-center gap-3 py-[.5dvh] px-[1.1dvh]  border-black/40 border-[0.16dvh] rounded-[.8dvh] bg-[#e7ded5]">
                  <p className="text-[1.3dvh] text-[#3F1A01]  font-bold">
                    Cart{" "}
                  </p>
                  <img
                    src="./images/fi_shopping-cart.svg"
                    alt="ShoppingCartIcon"
                    className="w-[1.4dvh] h-[1.4dvh]  cursor-pointer"
                    loading="lazy"
                  />
                </div>
              </button>
            </div>
          </div>
          <div className="w-full flex mt-[2dvh]">
            {status === "isLoading" ? (
              ""
            ) : (
              <div
                id="slider-container"
                className="w-1/4 mx-auto relative  py-[0.8dvh]"
              >
                <Slider
                  currentSlide={currentSlide}
                  setCurrentSlide={setCurrentSlide}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            )}

            {products && products.length > 0 ? (
              productsIsLoading || isNaN(currentSlideId) ? (
                <ProductsLoader />
              ) : (
                <div
                  id="shop2-container"
                  className="px-[1.2dvw] w-3/4 grid gap-[.9dvh] grid-cols-3 py-[0.8dvh] mx-auto"
                >
                  {products.map((item, i) => (
                    <ProductsCard
                      key={item.id}
                      item={item}
                      handleAddToCart={handleAddToCart}
                      i={i}
                      handleDecreaseQty={handleDecreaseQty}
                      handleIncreaseQty={handleIncreaseQty}
                      cartItems={cartItems}
                      setCartItems={setCartItems}
                      products={products}
                    />
                  ))}
                </div>
              )
            ) : searchQuery ? (
              <p className="text-center w-full text-[3dvh] text-main mt-[2dvh]">
                No products found!!
              </p>
            ) : (
              <ProductsLoader />
            )}
          </div>
        </div>
        <div className="bg-white z-50">
          {/* handlePrevPage   */}
          {/* //pagination handleNextPage meta currentPage*/}
          {/* <div className="pagination py-[0.4dvh]">
            <div className="text-[1.2dvh] text-center flex justify-center ">
              <button
                onClick={handlePrevPage}
                className={`bg-main text-white p-[.4dvh] rounded-[.9dvh] cursor-pointer ${
                  currentPage !== 1 ? "" : "opacity-40 pointer-events-none"
                } `}
              >
                <img
                  src="./images/fe--arrow-left.svg"
                  alt="back"
                  className=""
                  loading="lazy"
                />
              </button>
              <p className="px-[.6dvh] text-main my-auto">
                <span>{currentPage}</span> of{" "}
                <span>{meta?.last_page || 1}</span>
              </p>
              <button
                onClick={handleNextPage}
                // disabled={currentPage !== ProductsData.meta?.last_page}
                className={`bg-main text-white p-[.4dvh] rounded-[.9dvh] cursor-pointer ${
                  currentPage !== meta?.last_page
                    ? ""
                    : "opacity-40 pointer-events-none"
                } `}
              >
                <img
                  src="./images/fe--arrow-right.svg"
                  alt="next"
                  className=""
                  loading="lazy"
                />
              </button>
            </div>
          </div> */}
          <Pagination
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            currentPage={currentPage}
            meta={meta}
          />
          {/* //pagination */}
          <div className="border-b-4"></div>
          <div className="">
            <div className=" ">
              <SelectedProducts
                cartItems={cartItems}
                setCartItems={setCartItems}
                handleDecreaseQty={handleDecreaseQty}
                handleIncreaseQty={handleIncreaseQty}
                setIsShow={setIsShow}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
