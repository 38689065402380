import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { fetchAllSlides } from "../../../services/Categories/categoriesSlice";

export default function Slider({
  currentSlide,
  setCurrentSlide,
  setCurrentPage,
}) {
  const dispatch = useDispatch();
  const { slides = [] } = useSelector((state) => state?.getCategories);
  // console.log("slides");
  const [depth, setDepth] = useState(150);
  const swiperRef = useRef(null);

  // console.log("currentSlide", currentSlide);
  // const allCategories = {
  //   id: 86,
  //   image:
  //     "https://testecommerce.vodoglobal.com/data/category/31_ejazgwczii.jpeg",
  //   parent: "",
  //   sort: 0,
  //   status: 1,
  //   title: "All",
  //   top: 1,
  // };

  useEffect(() => {
    // dispatch(fetchSlides());
    // dispatch(fetchStandardSlides());
    dispatch(fetchAllSlides());

    const updateDepth = () => setDepth(150);
    window.addEventListener("resize", updateDepth);

    return () => window.removeEventListener("resize", updateDepth);
  }, [dispatch]);

  const handleSlideChange = (swiper) => {
    const realSlideIndex = swiper.realIndex; // Use realIndex for the logical slide index
    setCurrentSlide(realSlideIndex); // Update parent component's state
    setCurrentPage(1);
  };

  const handleClickSlide = (slideIndex) => {
    if (swiperRef.current) {
      const loopIndex = swiperRef.current.slidesPerView; // Adjust for looped slides
      const targetIndex = swiperRef.current.loop
        ? slideIndex + loopIndex
        : slideIndex;
      swiperRef.current.slideToLoop(targetIndex); // Use slideToLoop for looped slides
      setCurrentSlide(slideIndex); // Ensure parent state syncs
    }
  };

  return (
    <div
      className="
        w-full h-[20vh] 
        relative mx-auto px-[.8dvh]
        overflow-hidden"
    >
      <div className="fixed">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true} // Keep the active slide centered
          slidesPerView={4}
          direction={"vertical"}
          loop={true} // Enable looping
          initialSlide={13}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: depth,
            modifier: 1,
            slideShadows: false,
          }}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          onSlideChange={handleSlideChange}
          className="h-[48dvh]"
        >
          {slides?.map((slide, index) => (
            <SwiperSlide key={slide.id} id={slide.id}>
              <div
                onClick={() => handleClickSlide(index)} // Navigate to the correct logical slide
                className={`cursor-pointer h-fit w-full pb-[0.2dvh] px-[.6dvh] pt-[1dvh]
                  rounded-[1.4dvw] border-[0.16dvw] border-black/20
                  shadow-custom flex flex-col bg-white justify-center gap-3 ${
                    currentSlide === index ? "!bg-[#FAF9F5]" : "" // Highlight active slide
                  }`}
              >
                <img
                  src={slide.image || "./images/slide-1.svg"} // Optional fallback
                  alt="Slide"
                  className="mx-auto h-[13dvw] w-[9dvw] object-cover"
                  loading="lazy"
                />
                <p
                  className={`text-center font-bold text-[1.8dvh] font-Raleway max-w-[16dvw] ${
                    slide?.descriptions?.[0]?.title.length > 12 &&
                    "leading-[1.8dvh]"
                  } mx-auto`}
                >
                  {slide?.descriptions?.[0]?.title || "No Name"}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
