import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function SessionTimeout() {
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  let timeout;

  const resetTimeout = () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (location.pathname !== "/" && location.pathname !== "/login") {
        // Do not apply logic on the "/" path
        sessionStorage.removeItem("phone");
        sessionStorage.removeItem("cartItems");
        sessionStorage.removeItem("code");
        sessionStorage.removeItem("customer_id");
        navigate("/");
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
        }, 5000);
      }
      // }, 5 * 1000); // Set timeout for 5 seconds
    }, 5 * 60 * 1000); // Uncomment this for 5 minutes
  };

  useEffect(() => {
    // Function to detect user activity and reset timeout
    const handleActivity = () => {
      resetTimeout();
    };

    // Attach event listeners for user activity
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);
    window.addEventListener("click", handleActivity);

    // Initialize the timeout
    resetTimeout();

    // Cleanup function to remove event listeners and clear timeout
    return () => {
      clearTimeout(timeout);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("click", handleActivity);
    };
  }, [location.pathname]); // Rerun effect if the path changes

  return (
    <>
      {showPopup && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              background: "white",
              padding: "1.5dvh",
              borderRadius: "8px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
              textAlign: "center",
              maxWidth: "50dvw",
            }}
          >
            <h2 className="text-[2dvh] font-bold text-main">Session Ended</h2>
            <p className="text-[1.4dvh]">
              Oops! Your session has expired, and your cart has been reset for
              security purposes. Don't worry—you can start fresh and add your
              favorite items again.
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default SessionTimeout;
