import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchOrderDetails } from "../../services/Order/orderDetailsSlice";
// import Loader from "../../components/loader";
import Spinner from "../../components/spinner";
// import { fetchReservationDetails } from "../../services/Order/reservationDetailsSlice";
export default function DetailsOrder() {
  const navigate = useNavigate();
  const cartItems = JSON.parse(sessionStorage.getItem("cartItems"));
  const products = cartItems?.product;
  const products_qty = cartItems?.product_qty;
  // const specialist_name = cartItems?.professionals?.[index].user_name;
  // console.log(specialist_name);
  const dispatch = useDispatch();
  const orderID = sessionStorage.getItem("order_id");
  // const userId = sessionStorage.getItem("user_id");
  // console.log(userId);
  // const token = sessionStorage.getItem("token");
  // console.log(orderID);
  // Replace 123 with the actual order ID
  useEffect(() => {
    // dispatch(fetchReservationDetails({ userId, token }));
    dispatch(fetchOrderDetails({ orderID }));
  }, [dispatch]);
  const purchasesProducts = products.filter(
    (product) => product?.type === "Purchases" || product?.type === "purchases"
  );
  const services = products.filter(
    (product) => product?.type !== "Purchases" && product?.type !== "purchases"
  );
  const { data, loading, error } = useSelector((state) => state.orderDetails);

  console.log("data", data);
  console.log(loading);
  const orderSubtotalPrice = data?.data?.subtotal;
  const orderTotalPrice = data?.data?.total;

  const orderTotalTax = data?.data?.tax;
  const orderCurrency = data?.data?.currency;
  const orderData = data?.data?.custom_details;
  console.log("datadetails", orderData);
  const renderTable = (title, d) => (
    <div className="w-full max-w-[92dvw] bg-white shadow-custom rounded-lg overflow-hidden my-8">
      <h2 className="font-extrabold text-main text-center text-[2.5dvh] mb-4">
        {title}
      </h2>
      <table className="table-auto w-full">
        <thead className="bg-main">
          <tr className="text-left text-white font-semibold">
            <th className="px-[1dvh] py-[.6dvh] text-[1dvh]">Image</th>
            <th className="px-[1dvh] py-[.6dvh] text-[1dvh]">Name</th>
            <th className="px-[0.2dvh] py-[.6dvh] text-[1dvh]">Quantity</th>
            {title == "Booked Services" && (
              <th className="px-[1dvh] py-[.6dvh] text-[1dvh]">Duration</th>
            )}
            {title == "Booked Services" && (
              <th className="px-[1dvh] py-[.6dvh] text-[1dvh]">Specialist</th>
            )}
            <th className="px-[1dvh] py-[.6dvh] text-[1dvh]">Price</th>
          </tr>
        </thead>
        <tbody>
          {d.map((product, index) => (
            <tr
              key={product?.id}
              className="border-b hover:bg-orange-50 transition"
            >
              <td className="px-[1dvh] py-[.6dvh]">
                <img
                  src={product?.image}
                  alt="product"
                  className="w-[5dvh] h-[5dvh] object-cover rounded-[1dvh]"
                  loading="lazy"
                />
              </td>
              <td className="px-[1dvh] py-[.6dvh] text-gray-700 font-bold text-[1.3dvh]">
                {product?.descriptions[0]?.name ||
                  product?.keyword ||
                  "No name"}
              </td>
              <td className="px-[1dvh] text-center py-[.6dvh] text-gray-700 font-bold text-[1.3dvh]">
                {products_qty[index]}
              </td>
              {title == "Booked Services" && (
                <td className="px-[1dvh] py-[.6dvh] text-gray-700 font-bold text-[1.3dvh]">
                  {product?.unit?.main_unit_id >= 60 &&
                    product?.unit?.main_unit_id / 60}{" "}
                  {product?.unit?.main_unit_id >= 60 ? "Hour" : "Minutes"}
                </td>
              )}
              {title == "Booked Services" && (
                <td className="px-[1dvh] py-[.6dvh] text-gray-700 font-bold text-[1.3dvh]">
                  {cartItems?.professionals?.[index].user_name}
                </td>
              )}
              <td className="px-[.6dvh] py-[.6dvh] text-gray-700 font-bold text-[1.3dvh]">
                {products_qty[index] * product?.price}
                {orderCurrency}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <>
      <div className="flex flex-col bg-verification justify-between min-h-screen">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="  flex flex-col items-center px-4 py-8 overflow-auto">
              {/* Header */}
              <h1 className="font-extrabold text-white text-[3dvh] text-center mb-10">
                Order Details
              </h1>

              {/* Tables */}
              {purchasesProducts.length > 0 &&
                renderTable("Products Receipt", purchasesProducts)}
              {services.length > 0 && renderTable("Booked Services", services)}

              {/* Start New Operation Button */}
            </div>

            <div className="mt-[3dvh]  bg-white w-[100dvw]  flex flex-col  py-[1.4dvh]">
              {/* Total Price Section */}
              <div className="total text-[1.4dvh] font-semibold px-[3dvh] py-[1dvh] rounded-[1dvh] text-main  flex gap-[1dvh] justify-between">
                Subtotal Price{" "}
                <span className="]">
                  {orderSubtotalPrice} {orderCurrency}
                </span>
              </div>

              {/* Total Tax Section */}
              <div className=" text-[1.4dvh] font-semibold px-[3dvh] text-main py-[0.5dvh] mt-2 rounded-[1dvh]  flex gap-[1dvh] justify-between">
                Total Tax{" "}
                <span className=" text-red-500">
                  {orderTotalTax} {orderCurrency}
                </span>
              </div>
              <hr className="w-[90%] border-main border-t-[1px] my-[1dvh] mx-auto" />
              <div className=" text-[2dvh] font-extrabold px-[3dvh] text-main py-[0.5dvh] mt-2 rounded-[1dvh]  flex gap-[1dvh] justify-between ">
                <div>Total</div>{" "}
                <div className=" ">
                  {" "}
                  {orderTotalPrice} {orderCurrency}{" "}
                </div>
              </div>

              {/* Start New Operation Button */}
              <button
                onClick={() => {
                  sessionStorage.removeItem("phone");
                  sessionStorage.removeItem("cartItems");
                  sessionStorage.removeItem("code");
                  sessionStorage.removeItem("customer_id");
                  navigate("/");
                }}
                className="bg-main mt-[2dvh] mx-auto capitalize hover:bg-main/60 border-[.2dvh] border-white/70 text-white text-[2dvh] font-medium px-[3dvh] py-[0.5dvh] rounded-[1dvh] shadow-custom transition-all"
              >
                Start New Operation
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
