import { Suspense } from "react";
import AppLayout from "../src/layout";
import Camera from "../src/pages/camera/camera";
import Welcome from "../src/pages/home/welcome";
import CodeVerification from "../src/pages/codeVerification/codeVerification";
import PhoneVerification from "../src/pages/phoneVerification/phoneVerification";
import DoneOrder from "./pages/orderDone/doneOrder";
import LogIn from "./pages/login/logIn";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Toaster } from "react-hot-toast";
import DetailsOrder from "./pages/orderDetails/detailsOrder";
import GetStart from "./pages/getStarted/getStart";
import Services from "./pages/services/Services";
import Shop from "./pages/shop/Shop";
import Payment from "./pages/payment/payment";
import Logout from "./layout/logout";
import SessionTimeout from "./components/SessionTimeout";
import { HelmetProvider, Helmet } from "react-helmet-async";
import PrivateRoute from "./components/PrivateRoute";

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ToastContainer />
        <Toaster />
        <SessionTimeout />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route element={<AppLayout />}>
              <Route
                path="/"
                element={
                  <>
                    <Helmet>
                      <title>Welcome</title>
                    </Helmet>
                    <PrivateRoute element={<Welcome />} />
                  </>
                }
              />
              <Route
                path="home"
                element={
                  <>
                    <Helmet>
                      <title>Get Started</title>
                    </Helmet>
                    <PrivateRoute element={<GetStart />} />
                  </>
                }
              />
              <Route
                path="services"
                element={
                  <>
                    <Helmet>
                      <title>Services</title>
                    </Helmet>
                    <PrivateRoute element={<Services />} />
                  </>
                }
              />
              <Route
                path="shop"
                element={
                  <>
                    <Helmet>
                      <title>Shop</title>
                    </Helmet>
                    <PrivateRoute element={<Shop />} />
                  </>
                }
              />
              <Route
                path="camera"
                element={
                  <>
                    <Helmet>
                      <title>Camera</title>
                    </Helmet>
                    <PrivateRoute element={<Camera />} />
                  </>
                }
              />
              <Route
                path="phoneVerification"
                element={
                  <>
                    <Helmet>
                      <title>Phone Verification</title>
                    </Helmet>
                    <PhoneVerification />
                  </>
                }
              />
              <Route
                path="codeVerification"
                element={
                  <>
                    <Helmet>
                      <title>Code Verification</title>
                    </Helmet>
                    <CodeVerification />
                  </>
                }
              />
              <Route
                path="payment"
                element={
                  <>
                    <Helmet>
                      <title>Payment</title>
                    </Helmet>
                    <PrivateRoute element={<Payment />} />
                  </>
                }
              />
              <Route
                path="doneOrder"
                element={
                  <>
                    <Helmet>
                      <title>Order Completed</title>
                    </Helmet>
                    <PrivateRoute element={<DoneOrder />} />
                  </>
                }
              />
              <Route
                path="detailsOrder"
                element={
                  <>
                    <Helmet>
                      <title>Order Details</title>
                    </Helmet>
                    <PrivateRoute element={<DetailsOrder />} />
                  </>
                }
              />
              <Route path="login" element={<LogIn />} />
              <Route path="logout" element={<Logout />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
