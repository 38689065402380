// import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { secureBusinessKey, apiEndpoints } from "../.info";
import { fetchServices } from "../api/services";
export const getServicesData = createAsyncThunk(
  "services/getServicesData",
  async ({ pageNumber, keyWord, currentPage }, { rejectWithValue }) => {
    try {
      const { data } = await fetchServices({
        pageNumber,
        keyWord,
        currentPage,
      });
      // console.log(data);
      return data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const initialValues = {
  services: [],
  isLoading: false,
  isError: null,
  meta: null,
};

const servicesSlice = createSlice({
  name: "services",
  initialState: initialValues,
  extraReducers: (builder) => {
    builder.addCase(getServicesData.fulfilled, (state, action) => {
      state.isLoading = false;
      const { data, ...rest } = action.payload.data;
      state.services = data;
      // console.log(state.services);
      state.token = action.payload.jwt;
      // console.log(action.payload);
      state.meta = rest;
    });
    builder.addCase(getServicesData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getServicesData.rejected, (state, action) => {
      state.isError = action.error;
      state.isLoading = false;
      // console.log(action.error);
    });
  },
  reducers: {},
});

export const servicesReducer = servicesSlice.reducer;
