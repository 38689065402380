export const secureBusinessKey = process.env.REACT_APP_SECURE_BUSINESS_KEY;
console.log(process.env.REACT_APP_ORDER_DETAILS_ENDPOINT);
export const apiEndpoints = {
  addToCart: {
    url: `${process.env.REACT_APP_ECOMMERCE_BASE_URL}${process.env.REACT_APP_ADD_TO_CART_ENDPOINT}`,
  },
  authentication: {
    url: `${process.env.REACT_APP_ACCOUNTS_BASE_URL}${process.env.REACT_APP_AUTHENTICATION_ENDPOINT}`,
  },
  categories: {
    url: `${process.env.REACT_APP_ECOMMERCE_BASE_URL}${process.env.REACT_APP_CATEGORIES_ENDPOINT}`,
    apikey: process.env.REACT_APP_CATEGORIES_API_KEY,
  },
  standardCategories: {
    url: ({ standardProducts }) =>
      `${process.env.REACT_APP_ACCOUNTSPROD_BASE_URL}${process.env.REACT_APP_STANDARD_CATEGORIES_ENDPOINT}&products_ids=${standardProducts}`,
  },
  codeVerification: {
    url: `${process.env.REACT_APP_ACCOUNTING_BASE_URL}${process.env.REACT_APP_CODE_VERIFICATION_ENDPOINT}`,
    apikey: process.env.REACT_APP_CODE_VERIFICATION_API_KEY,
  },
  employees: {
    url: (serviceId) =>
      `${process.env.REACT_APP_HR_BASE_URL}${process.env.REACT_APP_EMPLOYEES_ENDPOINT}?service_skill_id=${serviceId}`,
    uuid: process.env.REACT_APP_EMPLOYEES_UUID,
  },
  getFromCart: {
    url: `${process.env.REACT_APP_ECOMMERCE_BASE_URL}${process.env.REACT_APP_GET_FROM_CART_ENDPOINT}`,
  },
  orderReservation: {
    url: `${process.env.REACT_APP_CALENDAR_BASE_URL}${process.env.REACT_APP_ORDER_RESERVATION_ENDPOINT}`,
  },
  orderDetails: {
    url: (orderId) =>
      `${process.env.REACT_APP_ECOMMERCE_BASE_URL}${process.env.REACT_APP_ORDER_DETAILS_ENDPOINT}/${orderId}?get_history&get_order_total`,
    uuid: process.env.REACT_APP_EMPLOYEES_UUID,
  },
  reservationDetails: {
    url: (userId) =>
      `${process.env.REACT_APP_CALENDAR_BASE_URL}${process.env.REACT_APP_RESERVATION_DETAILS_ENDPOINT}/${userId}`,
  },
  orderId: {
    url: `${process.env.REACT_APP_ECOMMERCE_BASE_URL}${process.env.REACT_APP_ORDER_ID_ENDPOINT}`,
  },
  getProducts: {
    url: ({
      currentPage,
      currentSlideID,
      keyWord,
      currentSlideTitle,
      currentSlideType,
    }) =>
      currentSlideTitle.includes("All")
        ? `${process.env.REACT_APP_ECOMMERCE_BASE_URL}${process.env.REACT_APP_GET_PRODUCTS_ENDPOINT}?sku=&page=${currentPage}&has_no_service&product_kind&min_price&max_price&keyword=${keyWord}&name&view=&per_page=9`
        : `${process.env.REACT_APP_ECOMMERCE_BASE_URL}${process.env.REACT_APP_GET_PRODUCTS_ENDPOINT}?sku=&page=${currentPage}&has_no_service&product_kind&keyword=${keyWord}&name&view=&category_type=${currentSlideType}&per_page=9&category_id=${currentSlideID}`,
    apikey: process.env.REACT_APP_CATEGORIES_API_KEY,
  },
  getStandardProducts: {
    url: () =>
      `${process.env.REACT_APP_ECOMMERCE_BASE_URL}${process.env.REACT_APP_GET_STANDARD_PRODUCTS_ENDPOINT}`,
    apikey: process.env.REACT_APP_CATEGORIES_API_KEY,
  },
  employeesSearch: {
    url: (serviceId, branchId) =>
      `${process.env.REACT_APP_HR_BASE_URL}${process.env.REACT_APP_EMPLOYEES_SEARCH_ENDPOINT}?service_skill_id=${serviceId}&branch_id=${branchId}&name=`,
    uuid: process.env.REACT_APP_EMPLOYEES_UUID,
  },
  sendCode: {
    url: `${process.env.REACT_APP_ACCOUNTING_BASE_URL}${process.env.REACT_APP_SEND_CODE_ENDPOINT}`,
    apikey: process.env.REACT_APP_CODE_VERIFICATION_API_KEY,
  },
  getServices: {
    url: ({ currentPage = 1, keyWord }) =>
      `${process.env.REACT_APP_ECOMMERCE_BASE_URL}${process.env.REACT_APP_GET_SERVICES_ENDPOINT}?sku=&page=${currentPage}&type=Service&product_kind&min_price&max_price&keyword=${keyWord}&name&view=&per_page=6`,
    apikey: process.env.REACT_APP_CATEGORIES_API_KEY,
  },
  changeQty: {
    url: (propsId) =>
      `${process.env.REACT_APP_ECOMMERCE_BASE_URL}${process.env.REACT_APP_CHANGE_QTY_ENDPOINT}/${propsId}`,
  },
};
// export const secureBusinessKey = "4765066450c0bd66325.48403130";
// export const apiEndpoints = {
//   addToCart: {
//     url: "https://testecommerce.vodoglobal.com/api/v2/abandoned_order",
//   },
//   authentication: {
//     url: "https://testaccounts.vodoerp.com/api/login",
//   },
//   categories: {
//     // url: "https://testecommerce.vodoglobal.com/api/v1/ecomm_categories?not_empty=yes",
//     url: "https://testecommerce.vodoglobal.com/api/v1/ecomm_categories?has_no_service_products=yes",
//     // url: "https://testecommerce.vodoglobal.com/api/v1/ecomm_categories",
//     apikey: "5f28583f26a1a",
//   },
//   standardCategories: {
//     url: ({ standardProducts }) =>
//       `https://accounts.vodoerp.com/api/standard_catlog/standard_categories?view=list&products_ids=${standardProducts}`,
//   },
//   codeVerification: {
//     url: "https://taccounting.vodoerp.com/api/kiosk_code/vertified_code",
//     apikey: "efe2db322a53",
//   },
//   employees: {
//     url: (serviceId) =>
//       `https://testhr.vodoglobal.com/api/v1/employees-skill?service_skill_id=${serviceId}`,
//     uuid: "630ca2f4885f8",
//   },
//   employeeAvailability: {
//     url: () => ``,
//   },
//   getFromCart: {
//     url: "https://testecommerce.vodoglobal.com/api/v2/abandoned_order",
//   },
//   orderReservation: {
//     url: "https://testcalendar.vodoglobal.com/api/add_new_reservation",
//   },
//   orderId: {
//     url: "https://testecommerce.vodoglobal.com/api/v2/create_new_order?kiosk",
//   },
//   getProducts: {
//     url: ({
//       currentPage,
//       currentSlideID,
//       keyWord,
//       currentSlideTitle,
//       currentSlideType,
//     }) =>
//       currentSlideTitle.includes("All")
//         ? `https://testecommerce.vodoglobal.com/api/v1/ecomm_products?sku=&page=${currentPage}&has_no_service&product_kind&min_price&max_price&keyword=${keyWord}&name&view=&per_page=9`
//         : `https://testecommerce.vodoglobal.com/api/v1/ecomm_products?sku=&page=${currentPage}&has_no_service&product_kind&keyword=${keyWord}&name&view=&category_type=${currentSlideType}&per_page=9&category_id=${currentSlideID}`,
//     apikey: "5f28583f26a1a",
//   },
//   getStandardProducts: {
//     url: () =>
//       `https://testecommerce.vodoglobal.com/api/v1/ecomm_products?get_standard_products_ids`,
//     apikey: "5f28583f26a1a",
//   },
//   employeesSearch: {
//     url: (serviceId, branchId) =>
//       `https://testhr.vodoglobal.com/api/v1/employees-skill?service_skill_id=${serviceId}&branch_id=${branchId}&name=`,
//     uuid: "630ca2f4885f8",
//   },
//   sendCode: {
//     url: "https://taccounting.vodoerp.com/api/kiosk_code/send_code",
//     apikey: "efe2db322a53",
//   },
//   getServices: {
//     url: ({ currentPage = 1, keyWord }) =>
//       `https://testecommerce.vodoglobal.com/api/v1/ecomm_products?sku=&page=${currentPage}&type=Service&product_kind&min_price&max_price&keyword=${keyWord}&name&view=&per_page=6`,
//     apikey: "5f28583f26a1a",
//   },
//   changeQty: {
//     url: (propsId) =>
//       `https://testecommerce.vodoglobal.com/api/v2/change_item_qty_or_remove/${propsId}`,
//   },
// };
