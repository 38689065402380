import { Navigate } from "react-router-dom";

const isUserLoggedIn = () => {
  return sessionStorage.getItem("token") !== "undefined";
};
console.log(isUserLoggedIn());
// PrivateRoute Component to protect routes that require login
const PrivateRoute = ({ element }) => {
  const isAuthenticated = isUserLoggedIn(); // Check if user is logged in

  if (!isAuthenticated) {
    // Redirect to login page if user is not logged in
    return <Navigate to="/login" replace />;
  }

  // Render the element (protected page) if the user is logged in
  return element;
};
export default PrivateRoute;
