import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { secureBusinessKey, apiEndpoints } from "../api/apiEndpoints";
// import { useState } from "react";

export const getOrderIdData = createAsyncThunk(
  "order/getOrderIdData",
  async () => {
    const cartItems = sessionStorage.getItem("cartItems");
    // console.log("cartItems", cartItems);

    const servicesIdArray = JSON.parse(cartItems).product_id;
    const servicesQtyArray = JSON.parse(cartItems).product_qty;
    const employeesIdArray = JSON.parse(cartItems).professionals_id;
    const user_id = sessionStorage.getItem("user_id");

    const options = {
      url: apiEndpoints.orderId.url,
      method: "POST",
      headers: {
        "Accept-Language": "en",
        "secure-business-key": secureBusinessKey,
      },
      data: {
        user_id: user_id,
        product_id: servicesIdArray,
        item_qty: servicesQtyArray,
        employee_id: employeesIdArray,
      },
    };

    try {
      const { data } = await axios.request(options);
      const orderID = data?.data?.order_id;
      const haveService = data?.have_service;
      // console.log(haveService, "haveService");

      // Push the orderID into sessionStorage
      sessionStorage.setItem("order_id", orderID);
      sessionStorage.setItem("have_service", haveService);

      // console.log("orderID", orderID);
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  }
);

const initialValues = {
  orderId: null,
  isLoading: false,
  isError: null,
};

const orderIdSlice = createSlice({
  name: "order",
  initialState: initialValues,
  extraReducers: (builder) => {
    builder
      .addCase(getOrderIdData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.orderDone = action.payload;
      })
      .addCase(getOrderIdData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrderIdData.rejected, (state, action) => {
        state.isError = action.error;
        state.isLoading = false;
      });
  },
});

export const orderIdReducer = orderIdSlice.reducer;
