import axiosClient from "./axiosClient";
import { apiEndpoints, secureBusinessKey } from "./apiEndpoints";

export const fetchCategories = () =>
  axiosClient.get(apiEndpoints.categories.url, {
    headers: {
      apikey: apiEndpoints.categories.apikey,
      "secure-business-key": secureBusinessKey,
      apiconnection: "appmobile",
      lang: "en",
    },
  });

export const fetchStandardProducts = () =>
  axiosClient.get(apiEndpoints.getStandardProducts.url(), {
    headers: {
      apikey: apiEndpoints.getStandardProducts.apikey,
      "secure-business-key": secureBusinessKey,
      lang: "en",
      apiconnection: "appmobile",
    },
  });

export const fetchStandardCategories = ({ standardProducts }) =>
  axiosClient.get(
    apiEndpoints.standardCategories.url({
      standardProducts: standardProducts.join(","),
    }),
    {
      headers: {
        lang: "en",
      },
    }
  );
