import axiosClient from "./axiosClient";
import { apiEndpoints, secureBusinessKey } from "./apiEndpoints";

export const fetchProducts = ({
  currentPage,
  currentSlideID,
  keyWord,
  currentSlideTitle,
  currentSlideType,
}) =>
  axiosClient.get(
    apiEndpoints.getProducts.url({
      currentPage,
      currentSlideID,
      keyWord,
      currentSlideTitle,
      currentSlideType,
    }),
    {
      headers: {
        apikey: apiEndpoints.getProducts.apikey,
        "secure-business-key": secureBusinessKey,
        lang: "en",
        apiconnection: "appmobile",
      },
    }
  );
