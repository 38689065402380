import React, { createContext, useEffect, useState } from "react";

// Create the context
export const ServiceSearchQueryContext = createContext();

// Create the provider component
export const ServiceSearchQueryProvider = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  // Reset currentPage to 1 when searchQuery changes
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);
  return (
    <ServiceSearchQueryContext.Provider
      value={{ searchQuery, setSearchQuery, currentPage, setCurrentPage }}
    >
      {children}
    </ServiceSearchQueryContext.Provider>
  );
};
