import React from "react";
// import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { fetchOrderDetails } from "../../services/Order/orderDetailsSlice";
// import Loader from "../../components/loader";
export default function DoneOrder() {
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  // const handleFetchOrderDetails = () => {
  //   const orderID = sessionStorage.getItem("order_id");
  //   dispatch(fetchOrderDetails({ orderID })); // Replace 123 with the actual order ID
  // };
  // const { data, loading } = useSelector((state) => state.orderDetails);
  // console.log("data", data);
  return (
    <>
      <article className="flex flex-col justify-center h-[100vh]">
        <div className="md:px-[37px] lg:px-[47px]">
          <div className="w-full flex flex-col justify-center items-center py-4">
            <img
              src="./images/doneOrder.png"
              alt="doneOrder"
              className="w-[26vw]"
              loading="lazy"
            />

            <p className="text-[16px] sm:text-[22px] md:text-[30px] lg:text-[38px] xl:text-[45px]  2xl:text-[56px]  3xl:text-[66px] 4xl:text-[75px] 5xl:text-[80px] 6xl:text-[87px] 7xl:text-[100px] text-center text-gray-600">
              Order Done Successfully
            </p>
          </div>
          <div className="w-full flex justify-center items-center gap-[1dvh]">
            <div
              onClick={() => {
                sessionStorage.removeItem("phone");
                sessionStorage.removeItem("cartItems");
                sessionStorage.removeItem("code");
                sessionStorage.removeItem("customer_id");
                navigate("/");
              }}
              className="cursor-pointer"
            >
              <p className="p-4 bg-main text-white text-[16px] sm:text-[22px] md:text-[30px] lg:text-[38px] xl:text-[45px]  2xl:text-[56px]  3xl:text-[66px] 4xl:text-[75px] 5xl:text-[80px] 6xl:text-[87px] 7xl:text-[100px] rounded-[0.5dvh] text-center ">
                New Operation
              </p>
            </div>

            <button
              onClick={() => navigate("/detailsOrder")}
              className="p-4 bg-main text-white text-[16px] sm:text-[22px] md:text-[30px] lg:text-[38px] xl:text-[45px]  2xl:text-[56px]  3xl:text-[66px] 4xl:text-[75px] 5xl:text-[80px] 6xl:text-[87px] 7xl:text-[100px] rounded-[0.5dvh] text-center "
            >
              Order Details
            </button>
          </div>
        </div>
      </article>
    </>
  );
}
