//
//
//
import "./index.css";
import App from "./App";
import React from "react";
import { Store } from "./services/stores/store";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { register } from "swiper/element/bundle";
import { EmployeesProvider } from "./contexts/EmployeesContext";
import { HelmetProvider } from "react-helmet-async";
import { ServiceSearchQueryProvider } from "./contexts/ServiceSearchQueryContext";

register();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={Store}>
    <ServiceSearchQueryProvider>
      <EmployeesProvider>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </EmployeesProvider>
    </ServiceSearchQueryProvider>
  </Provider>
);
